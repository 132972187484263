<script setup>
import PageHeader from "@/components/page-header.vue";
import {
  InquiriesByManagers as InquiriesByManagers,
  Filters as Filters,
  OperationCodesKPI as OperationCodesKPI,
  OperationCounterpartiesKPI as OperationCounterpartiesKPI,
  OperationTerminal as OperationTerminal,
  OperationCustomer as OperationCustomer,
} from "@/views/pages/statistics/components"
import {onMounted, onUnmounted, ref} from "vue";

let title = 'Statistics'
let items = [
  {
    text: "Home",
    href: "/",
  },
  {
    text: "Statistics",
    active: true,
  },
]

const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 50;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <section>
    <div class="row justify-content-center">

      <div id="sticky-filters"
           class="col-12" style="z-index: 100" :class="{ 'sticky-item': isScrolled }" >
        <div class="card mb-3 border-bottom border-top-0 border-start-0 border-end-0 border-4" :class="{ 'border-dark shadow-lg pt-4': isScrolled }" style="transition: .2s">
          <div class="card-body pb-2" style="transition: .2s">
            <Filters :isScrolled="isScrolled"/>
          </div>
        </div>
      </div>

      <div class="col-12 ">
        <InquiriesByManagers/>
        <OperationCodesKPI/>
        <OperationCustomer/>
        <OperationCounterpartiesKPI/>
        <OperationTerminal />
      </div>
    </div>
  </section>
</template>

<style scoped>
.content {
  flex: 1;
  padding: 16px;
}

.sticky-item {
  position: sticky;
  top: 50px; /* Distance from the top of the viewport */
  z-index: 100; /* Ensures it stays above other elements */
}
</style>