<script>
export default {
  data() {
    return {
      showModal: false,
      manager: null,
      per_page: 10,
      active_page: 1,

      order_by: "company.name",
      order_direction: "asc" // "asc" or "desc"
    }
  },
  methods: {
    openModal(manager) {
      this.active_page = 1
      this.manager = manager
      this.showModal = true
    },
    formatNumber(value, min, max) {
      return parseFloat(value || '0').toLocaleString(undefined, {
        minimumFractionDigits: min || 0, maximumFractionDigits: max || 0
      })
    },
    setOrderBy(key) {
      if (this.order_by === key) {
        this.order_direction = this.order_direction === "asc" ? "desc" : "asc";
      } else {
        this.order_by = key;
        this.order_direction = "asc";
      }
    },
  },
  computed: {
    sortedOrders() {
      if (!this.manager || !this.manager.companies_orders) return [];

      const orders = [...this.manager.companies_orders];
      const keys = this.order_by.split(".");

      return orders.sort((a, b) => {
        let valA = a;
        let valB = b;

        for (const key of keys) {
          valA = valA?.[key];
          valB = valB?.[key];
        }

        if (valA === undefined || valB === undefined) return 0;

        const comparison = valA > valB ? 1 : valA < valB ? -1 : 0;

        return this.order_direction === "asc" ? comparison : -comparison;
      });
    },

    paginatedOrders() {
      const start = (this.active_page - 1) * this.per_page;
      const end = this.active_page * this.per_page;

      return this.sortedOrders.slice(start, end);
    },

    total_pages() {
      if (!this.manager || !this.manager.companies_orders) return 1
      return Math.ceil((this.manager.companies_orders || []).length / this.per_page)
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" hide-footer centered size="xl">
    <template v-slot:title v-if="manager">
      {{ manager.manager.username }}
    </template>

    <!-- Small Tables -->
    <template v-if="manager">

      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr class="fs-14 table-light">
            <th scope="col">#</th>
            <th style="max-width: 100px" @click="setOrderBy('company.name')" class="cursor-pointer" scope="col">
              Customer
              <i v-if="order_by === 'company.name'" class="align-middle" :class="{
                'ri-arrow-up-s-fill text-success' : order_direction === 'asc',
                'ri-arrow-down-s-fill text-danger' : order_direction === 'desc',
              }"></i>
            </th>
            <th @click="setOrderBy('total_orders')" class="text-center cursor-pointer" scope="col">
              Total Orders
              <i v-if="order_by === 'total_orders'" class="align-middle" :class="{
                'ri-arrow-up-s-fill text-success' : order_direction === 'asc',
                'ri-arrow-down-s-fill text-danger' : order_direction === 'desc',
              }"></i>
            </th>
            <th @click="setOrderBy('total_invoices_percentage')" class="text-center cursor-pointer" scope="col">
              Total Invoices
              <i v-if="order_by === 'total_invoices_percentage'" class="align-middle" :class="{
                'ri-arrow-up-s-fill text-success' : order_direction === 'asc',
                'ri-arrow-down-s-fill text-danger' : order_direction === 'desc',
              }"></i>
            </th>
            <th @click="setOrderBy('total_invoices_paid_percentage')" class="text-center cursor-pointer" scope="col">
              Invoices Paid
              <i v-if="order_by === 'total_invoices_paid_percentage'" class="align-middle" :class="{
                'ri-arrow-up-s-fill text-success' : order_direction === 'asc',
                'ri-arrow-down-s-fill text-danger' : order_direction === 'desc',
              }"></i>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="fs-14 align-middle" v-for="(order, index) in paginatedOrders" :key="`order_${order}`">
            <th scope="row">
              {{ (active_page - 1) * per_page + index + 1 }}
            </th>
            <td style="max-width: 100px">
              {{ order.company ? order.company.name : '---' }}
            </td>
            <td class="text-center">{{ order.total_orders || 0 }}</td>
            <td class="text-center">
              <span>{{ formatNumber(order.total_invoices_percentage, 1, 1) }}%</span>
              <i class="text-muted mdi mdi-slash-forward"></i>
              <span class="text-muted">{{ formatNumber(order.total_invoices) }}</span>
            </td>
            <td class="text-center">
              <span>{{ formatNumber(order.total_invoices_paid_percentage, 1, 1) }}%</span>
              <i class="text-muted mdi mdi-slash-forward"></i>
              <span class="text-muted">{{ order.total_invoices_paid || 0 }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-end py-0 w-100">
        <ul class="pagination pagination-separated my-0">
          <li @click="active_page <= 1 ? null : active_page--" class="page-item"
              :class="{'disabled': active_page <= 1}">
            <a class="page-link cursor-pointer"> Prev </a>
          </li>
          <li v-for="page in total_pages" :key="`page_${page}`" class="page-item"
              :class="{'active': page === active_page}" @click="active_page = page">
            <a class="page-link cursor-pointer">{{ page }}</a>
          </li>
          <li @click="active_page >= total_pages ? null : active_page++" class="page-item"
              :class="{'disabled': active_page === total_pages}">
            <a class="page-link cursor-pointer"> Next </a>
          </li>
        </ul>
      </div>
    </template>

  </b-modal>
</template>

<style scoped>

</style>