<script>
import {
  CountTo
} from "vue3-count-to";
import axios from "axios";

import {ManagerCustomerOrders} from "@/views/pages/statistics/modals"

export default {
  components: {
    CountTo,
    ManagerCustomerOrders
  },
  data() {
    return {
      kpi: null,
      is_loading: false
    }
  },
  methods: {
    async getKpi() {
      try {
        this.is_loading = true
        const {start_date, end_date} = this.$route.query;
        let response = await axios.get('/customer/kpi/', {
          params: (start_date && end_date) ? {
            date_from: start_date,
            date_to: end_date,
          } : {}
        })
        this.kpi = response.data
        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error loading Codes KPI")
      }
    },
  },
  computed: {
    date_from() {
      return this.$route.query.start_date
    },
    date_to() {
      return this.$route.query.end_date
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getKpi();
        }
      },
      deep: true
    },
  },
  async mounted() {
    await this.getKpi()
  }
}
</script>

<template>

  <ManagerCustomerOrders ref="managerCustomerOrders"/>

  <b-card no-body class="pb-0">
    <b-card-header class="border-0 align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Operations - Customers</b-card-title>
    </b-card-header>

    <b-card-header class="p-0 border-0 bg-soft-light">
      <b-row v-if="kpi" class="g-0">
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to :startVal="0" :endVal="kpi.total_orders || 0" :duration="3000"></count-to>
            </h5>
            <p class="text-muted mb-0">Total orders</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.total_invoices_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.total_invoices || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Total invoices</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.total_invoices_paid_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.total_invoices_paid || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Invoices paid</p>
          </div>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body v-if="kpi">
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <thead>
          <tr class="fs-14">
            <th scope="col">Manager</th>
            <th scope="col">Total orders</th>
            <th scope="col">Total invoices</th>
            <th scope="col">Invoices Paid</th>
          </tr>
          </thead>
          <tbody>
          <tr class="fs-14" v-for="manager in kpi.assigned_managers" :key="`manager_${manager}`">
            <th>
              <a class="fw-semibold" :class="{
              'text-danger': !manager.manager.id
            }">
                {{ manager.manager.username || '-' }}
              </a>
            </th>
            <td>
              <span @click="$refs.managerCustomerOrders.openModal(manager)"
                    class="badge badge-soft-primary cursor-pointer">
                  {{
                  parseInt(manager.total_orders).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
              </span>
            </td>
            <td>
              <div>
                <span>{{
                    parseFloat(manager.total_invoices_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                    parseFloat(manager.total_invoices || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  }}
                </span>
              </div>
            </td>
            <td>
              <div>
                <span>{{
                    parseFloat(manager.total_invoices_paid_percentage || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1
                    })
                  }}%
                </span>
                <span class="text-muted">
                  <i class="mdi mdi-slash-forward"></i>{{
                    parseFloat(manager.total_invoices_paid || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })
                  }}
                </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>

</template>

<style scoped>

</style>