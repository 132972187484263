<script>
import {
  CountTo
} from "vue3-count-to";
import axios from "axios";

import {ManagerCompanies} from "@/views/pages/statistics/modals"

export default {
  components: {
    CountTo,
    ManagerCompanies
  },
  data() {
    return {
      kpi: null,
      is_loading: false
    }
  },
  methods: {
    async getKpi() {
      try {
        this.is_loading = true
        const {start_date, end_date} = this.$route.query;
        let response = await axios.get('/terminal/kpi/', {
          params: (start_date && end_date) ? {
            date_from: start_date,
            date_to: end_date,
          } : {}
        })
        this.kpi = response.data
        //
        // const extractCompanyData = (statuses, type) => {
        //   const status = statuses?.[type] || {};
        //   return {
        //     companies: status.companies || [],
        //     count: status.count || 0,
        //   };
        // };
        //
        // this.kpi.managers = this.kpi.managers.map(i => {
        //   const goodData = extractCompanyData(i.company_statuses, 'good');
        //   const averageData = extractCompanyData(i.company_statuses, 'average');
        //   const badData = extractCompanyData(i.company_statuses, 'bad');
        //
        //   return {
        //     ...i,
        //     good_companies: goodData.companies,
        //     good_companies_count: goodData.count,
        //     average_companies: averageData.companies,
        //     average_companies_count: averageData.count,
        //     bad_companies: badData.companies,
        //     bad_companies_count: badData.count,
        //   };
        // });

        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error loading Codes KPI")
      }
    },
  },
  computed: {
    date_from() {
      return this.$route.query.start_date
    },
    date_to() {
      return this.$route.query.end_date
    },

    total_containers_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.total_containers || 0), 0)
      } catch {
        return 0
      }
    },

    containers_with_customer_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.customer_stats.with_customers || 0), 0)
      } catch {
        return 0
      }
    },

    seal_images_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.content_stats.with_seal_images || 0), 0)
      } catch {
        return 0
      }
    },

    documents_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.content_stats.with_documents || 0), 0)
      } catch {
        return 0
      }
    },

    empty_containers_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.load_stats.empty || 0), 0)
      } catch {
        return 0
      }
    },

    laden_containers_count() {
      if (!this.kpi) return 0
      try {
        return this.kpi.reduce((a, b) => a + (b.load_stats.laden || 0), 0)
      } catch {
        return 0
      }
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getKpi();
        }
      },
      deep: true
    },
  },
  async mounted() {
    await this.getKpi()
  }
}
</script>

<template>

  <ManagerCompanies ref="managerCompanies"/>

  <b-card no-body class="pb-0">
    <b-card-header class="border-0 align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Operations - Terminals</b-card-title>
    </b-card-header>

    <b-card-header class="p-0 border-0 bg-soft-light">
      <b-row v-if="kpi" class="g-0">
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to :startVal="0" :endVal="total_containers_count" :duration="3000"></count-to>
            </h5>
            <p class="text-muted mb-0">Total containers count</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat((containers_with_customer_count / total_containers_count) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(containers_with_customer_count || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Containers with Customer</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat((seal_images_count / total_containers_count) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(seal_images_count || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Seal images</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat((documents_count / total_containers_count) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(documents_count || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Documents</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat((empty_containers_count / total_containers_count) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(empty_containers_count || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Empty containers</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat((laden_containers_count / total_containers_count) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(laden_containers_count || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Laden containers</p>
          </div>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body v-if="kpi">
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <thead>
          <tr class="fs-14">
            <th scope="col">Terminal</th>
            <th scope="col">Managers</th>
            <th scope="col">Containers</th>
            <th scope="col">Customer</th>
            <th scope="col">Seal Images</th>
            <th scope="col">Documents</th>
            <th scope="col">Empty</th>
            <th scope="col">Laden</th>
            <th scope="col">Statuses</th>
            <th scope="col">Orders</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="is_loading">
            <tr class="align-middle">
              <th colspan="8">
                <b-spinner style="width: 15px; height: 15px"></b-spinner>
              </th>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(terminal) in (kpi || [])"
                :key="`terminal_${terminal}`"
                class="fs-14 align-middle"
            >
              <th scope="row">
                <a class="fw-semibold">
                  {{ terminal.terminal_name }}
                </a>
              </th>

              <td>
                <div class="d-flex gap-2 align-items-center">
                  <span v-for="manager in (terminal.assigned_managers || [])" :key="`manager_${manager.id}`"
                        class="badge badge-soft-primary">
                    {{ manager.username }}
                  </span>
                </div>
              </td>

              <td>
                {{
                  parseFloat(terminal.total_containers || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
              </td>

              <td>
                <template v-if="terminal.customer_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.customer_stats.customer_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.customer_stats.with_customers || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <td>
                <template v-if="terminal.content_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.content_stats.images_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.content_stats.with_seal_images || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <td>
                <template v-if="terminal.content_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.content_stats.documents_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.content_stats.with_documents || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <td>
                <template v-if="terminal.load_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.load_stats.empty_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.load_stats.empty || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <td>
                <template v-if="terminal.load_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.load_stats.laden_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.load_stats.laden || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <td>
                <div v-if="terminal.status_stats" class="d-flex gap-2 align-items-center">
                  <span v-b-tooltip.hover :title="`Pending containers: ${terminal.status_stats.pending_percentage || 0}%`"
                        class="badge badge-soft-warning">
                    {{
                      parseFloat(terminal.status_stats.pending || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0, maximumFractionDigits: 0
                      })
                    }}
                  </span>
                  <span v-b-tooltip.hover :title="`Available containers: ${terminal.status_stats.available_percentage || 0}%`"
                        class="badge badge-soft-secondary">
                    {{
                      parseFloat(terminal.status_stats.available || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0, maximumFractionDigits: 0
                      })
                    }}
                  </span>
                  <span v-b-tooltip.hover :title="`Dispatched containers: ${terminal.status_stats.dispatched_percentage || 0}%`"
                        class="badge badge-soft-success">
                    {{
                      parseFloat(terminal.status_stats.dispatched || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0, maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </td>

              <td>
                <template v-if="terminal.order_stats">
                  <div>
                    <span>{{
                        parseFloat(terminal.order_stats.order_percentage || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1
                        })
                      }}%
                    </span>
                    <span class="text-muted">
                      <i class="mdi mdi-slash-forward"></i>
                      {{
                        parseFloat(terminal.order_stats.with_orders || 0).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                    </span>
                  </div>
                </template>
              </td>

              <!--              <td>-->
              <!--                <div>-->
              <!--                              <span>{{-->
              <!--                                  parseFloat(manager.completed_percentage || 0).toLocaleString(undefined, {-->
              <!--                                    minimumFractionDigits: 1,-->
              <!--                                    maximumFractionDigits: 1-->
              <!--                                  })-->
              <!--                                }}%-->
              <!--                              </span>-->
              <!--                  <span class="text-muted">-->
              <!--                                <i class="mdi mdi-slash-forward"></i>{{-->
              <!--                      parseFloat(manager.completed || 0).toLocaleString(undefined, {-->
              <!--                        minimumFractionDigits: 0,-->
              <!--                        maximumFractionDigits: 0-->
              <!--                      })-->
              <!--                    }}-->
              <!--                              </span>-->
              <!--                </div>-->
              <!--              </td>-->

              <!--              <th>-->
              <!--                <div class="d-flex align-items-center gap-2">-->
              <!--                  <span @click="$refs.managerCompanies.openModal(manager, manager.companies)"-->
              <!--                        class="badge badge-soft-primary cursor-pointer">-->
              <!--                    {{-->
              <!--                      (manager.companies || []).length-->
              <!--                    }}-->
              <!--                  </span>-->
              <!--                  <i class="mdi mdi-slash-forward text-muted"></i>-->
              <!--                  <span @click="$refs.managerCompanies.openModal(manager, manager.good_companies)"-->
              <!--                        class="badge badge-soft-success cursor-pointer">-->
              <!--                    {{ manager.good_companies_count || 0 }}-->
              <!--                  </span>-->
              <!--                  <span @click="$refs.managerCompanies.openModal(manager, manager.average_companies)"-->
              <!--                        class="badge badge-soft-warning cursor-pointer">-->
              <!--                    {{ manager.average_companies_count || 0 }}-->
              <!--                  </span>-->
              <!--                  <span @click="$refs.managerCompanies.openModal(manager, manager.bad_companies)"-->
              <!--                        class="badge badge-soft-danger cursor-pointer">-->
              <!--                    {{ manager.bad_companies_count || 0 }}-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </th>-->
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>

</template>

<style scoped>

</style>