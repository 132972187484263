<script>
import {
  CountTo
} from "vue3-count-to";
import axios from "axios";

import {ManagerCompanies} from "@/views/pages/statistics/modals"

export default {
  components: {
    CountTo,
    ManagerCompanies
  },
  data() {
    return {
      kpi: null,
      is_loading: false
    }
  },
  methods: {
    async getKpi() {
      try {
        this.is_loading = true
        const {start_date, end_date} = this.$route.query;
        let response = await axios.get('/counterparty/kpi/', {
          params: (start_date && end_date) ? {
            date_from: start_date,
            date_to: end_date,
          } : {}
        })
        this.kpi = response.data

        const extractCompanyData = (statuses, type) => {
          const status = statuses?.[type] || {};
          return {
            companies: status.companies || [],
            count: status.count || 0,
          };
        };

        this.kpi.managers = this.kpi.managers.map(i => {
          const goodData = extractCompanyData(i.company_statuses, 'good');
          const averageData = extractCompanyData(i.company_statuses, 'average');
          const badData = extractCompanyData(i.company_statuses, 'bad');

          return {
            ...i,
            good_companies: goodData.companies,
            good_companies_count: goodData.count,
            average_companies: averageData.companies,
            average_companies_count: averageData.count,
            bad_companies: badData.companies,
            bad_companies_count: badData.count,
          };
        });

        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error loading Codes KPI")
      }
    },
  },
  computed: {
    date_from() {
      return this.$route.query.start_date
    },
    date_to() {
      return this.$route.query.end_date
    },
    kpi_good_companies() {
      try {
        let count = this.kpi.company_statuses.good.count
        let companies = this.kpi.company_statuses.good.companies
        return {
          count: count || 0,
          companies: companies || []
        }
      } catch {
        return {
          count: 0,
          companies: []
        }
      }
    },
    kpi_average_companies() {
      try {
        let count = this.kpi.company_statuses.average.count
        let companies = this.kpi.company_statuses.average.companies
        return {
          count: count || 0,
          companies: companies || []
        }
      } catch {
        return {
          count: 0,
          companies: []
        }
      }
    },
    kpi_bad_companies() {
      try {
        let count = this.kpi.company_statuses.bad.count
        let companies = this.kpi.company_statuses.bad.companies
        return {
          count: count || 0,
          companies: companies || []
        }
      } catch {
        return {
          count: 0,
          companies: []
        }
      }
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (
            newQuery.start_date !== oldQuery.start_date ||
            newQuery.end_date !== oldQuery.end_date
        ) {
          this.getKpi();
        }
      },
      deep: true
    },
  },
  async mounted() {
    await this.getKpi()
  }
}
</script>

<template>

  <ManagerCompanies ref="managerCompanies"/>

  <b-card no-body class="pb-0">
    <b-card-header class="border-0 align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Operations - Counterparties</b-card-title>
    </b-card-header>

    <b-card-header class="p-0 border-0 bg-soft-light">
      <b-row v-if="kpi" class="g-0">
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to :startVal="0" :endVal="kpi.total || 0" :duration="3000"></count-to>
            </h5>
            <p class="text-muted mb-0">Total ctrs & wgns count</p>
          </div>
        </b-col>
        <b-col class="" cols="6" xxl="2" xl="4" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(kpi.completed_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(kpi.completed || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Ctrs & Wgns with Acts</p>
          </div>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body v-if="kpi">
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <thead>
          <tr class="fs-14">
            <th scope="col">Manager</th>
            <th scope="col">Total ctrs & wgns</th>
            <th scope="col">Ctrs & Wgns with Acts</th>
            <th scope="col">Companies count</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="is_loading">
            <tr class="align-middle">
              <th colspan="8">
                <b-spinner style="width: 15px; height: 15px"></b-spinner>
              </th>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(manager, index) in (kpi.managers || [])"
                :key="`manager_${manager.manager ? manager.manager : index}`"
                class="fs-14"
            >
              <th scope="row"><a class="fw-semibold" :class="{
              'text-danger': !manager.manager.id
            }">
                {{ manager.manager.id ? manager.manager.username : 'Unknown Manager' }}</a>
              </th>

              <td>
                <div>
                              <span>{{
                                  parseFloat(manager.total || 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                  })
                                }}</span>
                  <span class="text-muted">
                                <i class="mdi mdi-approximately-equal mx-1"></i>{{
                      parseFloat(((manager.total || 0) / (kpi.total || 1)) * 100).toLocaleString(undefined, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })
                    }}% of total
                              </span>
                </div>

              </td>

              <td>
                <div>
                              <span>{{
                                  parseFloat(manager.completed_percentage || 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1
                                  })
                                }}%
                              </span>
                  <span class="text-muted">
                                <i class="mdi mdi-slash-forward"></i>{{
                      parseFloat(manager.completed || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                              </span>
                </div>
              </td>

              <th>
                <div class="d-flex align-items-center gap-2">
                  <span @click="$refs.managerCompanies.openModal(manager, manager.companies)"
                        class="badge badge-soft-primary cursor-pointer">
                    {{
                      (manager.companies || []).length
                    }}
                  </span>
                  <i class="mdi mdi-slash-forward text-muted"></i>
                  <span @click="$refs.managerCompanies.openModal(manager, manager.good_companies)"
                        class="badge badge-soft-success cursor-pointer">
                    {{ manager.good_companies_count || 0 }}
                  </span>
                  <span @click="$refs.managerCompanies.openModal(manager, manager.average_companies)"
                        class="badge badge-soft-warning cursor-pointer">
                    {{ manager.average_companies_count || 0 }}
                  </span>
                  <span @click="$refs.managerCompanies.openModal(manager, manager.bad_companies)"
                        class="badge badge-soft-danger cursor-pointer">
                    {{ manager.bad_companies_count || 0 }}
                  </span>
                </div>
              </th>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </b-card-body>
  </b-card>

</template>

<style scoped>

</style>