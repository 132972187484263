<script>
import {CountTo} from "vue3-count-to";

export default {
  components: {CountTo},
  data() {
    return {
      showModal: false,
      manager: null,
      companies: [],
    }
  },
  computed: {
    manager_username() {
      if (!this.manager) return "Unknown Manager"
      return this.manager.manager.id ? this.manager.manager.username : 'Unknown Manager'
    }
  },
  methods: {
    openModal(manager, companies) {
      this.manager = manager
      this.companies = companies
      this.showModal = true
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" :title="manager_username" hide-footer
           centered size="xl"
  >
    <template v-if="manager">


      <b-row class="g-0 mb-3">
        <b-col class="bg-light" cols="12" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to :startVal="0" :endVal="manager.total || 0" :duration="3000"></count-to>
            </h5>
            <p class="text-muted mb-0">Total ctrs & wgns count</p>
          </div>
        </b-col>
        <b-col class="bg-light" cols="12" sm="6">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="text-success mb-1">
              {{
                parseFloat(manager.completed_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
              <span class="text-black-50 ms-1 fs-14">
                <i class="mdi mdi-slash-forward"></i>
                {{
                  parseFloat(manager.completed || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0, maximumFractionDigits: 0
                  })
                }}
              </span>
            </h5>
            <p class="text-muted mb-0">Ctrs & Wgns with Acts</p>
          </div>
        </b-col>
        <b-col cols="12">
          <p class="fs-14 border p-3 rounded text-muted mt-4">
            <i class="mdi mdi-information me-1"></i>
            This table lists the companies under the manager's supervision, showing total containers/wagons <br> and the
            percentage connected with acts.
          </p>
        </b-col>
      </b-row>
      <table class="table table-nowrap" :class="{
        'table-sm' : (companies || []).length > 10
      }">
        <thead>
        <tr class="fs-16">
          <th scope="col">#</th>
          <th scope="col">Company name</th>
          <th scope="col">Total ctrs & wgns</th>
          <th scope="col">Ctrs & Wgns with Acts</th>
        </tr>
        </thead>
        <tbody>
        <tr class="fs-16"
            :class="{
              'bg-soft-success' : parseFloat(company.completed_percentage || 0) >= 100
            }"
            v-for="(company, index) in (companies || [])" :key="`company_${company}`">
          <th scope="row">{{ index + 1 }}</th>
          <td>
            <a v-if="company.company" :href="`/accounts/counterparty/${company.company.slug}/`" target="_blank">
              {{ company.company.name }}
            </a>
          </td>
          <td>{{ company.total }}</td>
          <td>
            <span>
              {{
                parseFloat(company.completed_percentage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 1, maximumFractionDigits: 1
                })
              }}%
            </span>
            <i class="mdi mdi-slash-forward text-muted"></i>
            <span class="text-muted">
              {{ company.completed }} ctrs & wgns
            </span>
          </td>
        </tr>
        </tbody>
      </table>

    </template>
  </b-modal>
</template>

<style scoped>

</style>