<template>
  <b-accordion class="lefticon-accordion">
    <BAccordionItem :title="`${INQUIRY_KPI.response_time.label} - ${INQUIRY_KPI.response_time.weight}%`">
      <p class="fs-13 text-body lh-lg mb-2">
        {{ INQUIRY_KPI.response_time.description }} <br>
        <b class="fw-medium me-1">Target:</b> {{ INQUIRY_KPI.response_time.target }}
      </p>

      <div class="d-flex flex-column gap-2 mt-2">
        <div v-for="grade in INQUIRY_KPI.response_time.grades" :key="grade.label"
             class="d-flex align-items-center gap-2 fs-14">
          <span :class="`badge border border-${grade.badgeClass} text-${grade.badgeClass}`">
            {{ grade.label }}
          </span>
          <div class="text-muted">
            <small>{{ grade.description }}</small>
          </div>
        </div>
      </div>
    </BAccordionItem>
    <BAccordionItem :title="`${INQUIRY_KPI.completion.label} - ${INQUIRY_KPI.completion.weight}%`">
      <p class="fs-13 text-body lh-lg mb-2">
        {{ INQUIRY_KPI.completion.description }} <br>
        <b class="fw-medium me-1">Target:</b> {{ INQUIRY_KPI.completion.target }}
      </p>

      <div class="d-flex flex-column gap-2 mt-2">
        <div v-for="grade in INQUIRY_KPI.completion.grades" :key="grade.label"
             class="d-flex align-items-center gap-2 fs-14">
          <span :class="`badge border border-${grade.badgeClass} text-${grade.badgeClass}`">
            {{ grade.label }}
          </span>
          <div class="text-muted">
            <small>{{ grade.description }}</small>
          </div>
        </div>
      </div>
    </BAccordionItem>
    <BAccordionItem :title="`${INQUIRY_KPI.conversion.label} - ${INQUIRY_KPI.conversion.weight}%`">
      <p class="fs-13 text-body lh-lg mb-0">
        {{ INQUIRY_KPI.conversion.description }} <br>
        <b class="fw-medium me-1">Target:</b> {{ INQUIRY_KPI.conversion.target }}
      </p>
    </BAccordionItem>
    <BAccordionItem :title="`${INQUIRY_KPI.leads.label} - ${INQUIRY_KPI.leads.weight}%`">
      <p class="fs-13 text-body lh-lg mb-0">
        {{ INQUIRY_KPI.leads.description }} <br>
        <b class="fw-medium me-1">Target:</b> {{ INQUIRY_KPI.leads.target }}
      </p>
    </BAccordionItem>
  </b-accordion>
</template>

<script>
import {INQUIRY_KPI} from "@/core/KPI";

export default {
  data() {
    return {
      INQUIRY_KPI: INQUIRY_KPI
    }
  }
};
</script>
